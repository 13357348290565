<template>
  <div>
    <header-slot></header-slot>
    <b-row class="mb-1" align-v="center">
      <b-col cols="12" md="3" xl="2">
        <div class="rounded-lg" :class="isDarkSkin ? 'bg-dark' : 'bg-white'">
          <b-form-group
            label-cols-lg="4"
            label-cols="12"
            content-cols-lg="8"
            content-cols="12"
            class="p-50 px-lg-1 text-center font-weight-bolder"
          >
            <template #label>
              <feather-icon
                icon="CalendarIcon"
                size="20"
                class="mr-1 text-primary"
              />
              Year
            </template>
            <v-select
              v-model="yearSelected"
              :clearable="false"
              label="value"
              :options="optionsYears"
              placeholder="--Select--"
              :reduce="(option) => option.id"
            />
          </b-form-group>
        </div>
      </b-col>
      <b-col cols="auto">
        <b-button variant="outline-primary" @click="refreshTable()">
          <feather-icon icon="RefreshCcwIcon" /> Refresh
        </b-button>
      </b-col>
    </b-row>
    <b-table
      striped
      bordered
      :items="commissions"
      :fields="columns"
      foot-clone
      class="custom-table"
      ref="tableCommission"
      show-empty
      :busy.sync="isBusy"
      responsive
      sticky-header="65vh"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #empty="scope">
        <div
          class="d-flex justify-content-center align-items-center w-100 position-relative p-3"
          style="height: 80%"
        >
          <img
            src="@/assets/images/noData/no-data-score-card.svg"
            class="custom-img"
            alt="scope"
          />
          <span class="mt-3 text-uppercase custom-span"
            >There is no commission assigned</span
          >
        </div>
      </template>
      <template #head(user)="data">
        <div class="d-flex align-items-center justify-content-center">
          <feather-icon icon="UserIcon" class="text-primary mr-1" size="20" />
          <span class="text-primary h6 m-0" style="font-weight: bold">{{
            data.label
          }}</span>
        </div>
      </template>
      <template v-slot:cell(user)="data">
        <div class="text-center">
          {{ data.value }}
        </div>
      </template>
      <template
        v-for="(month, index1) in months"
        v-slot:[`cell(${month})`]="{ index, item }"
      >
        <a
          class="clickable"
          @click="openModalDetails(item, month)"
          :key="index1"
          style="font-size: 14px"
          >{{ getCommission(users[index], month) | currencyTwo | myMoney }}</a
        >
      </template>
      <template #foot(user)
        ><div
          class="text-center text-primary d-flex align-items-center justify-content-center"
        >
          <amg-icon icon="MoneyBagIcon" class="mr-1" size="20" />
          <span class="h5 text-primary m-0" style="font-weight: bold"
            >TOTAL</span
          >
        </div>
      </template>
      <template #foot()="data">
        <span style="font-size: 14px">{{
          calculateTotalMonth(data.column) | currencyTwo | myMoney
        }}</span>
      </template>
    </b-table>
    <DetailsCommission
      v-if="showCommission"
      :commissionData="commissionData"
      :yearSelected="yearSelected"
      :monthSelected="monthSelected"
      @closeModal="showCommission = false"
    />
  </div>
</template>

<script>
import CommissionService from "@/views/specialists/sub-modules/financial-approval/views/commissions/services/financial-commissions.service";
import moment from "moment";
import { mapGetters } from "vuex";
// MODAL
import DetailsCommission from "@/views/specialists/sub-modules/financial-approval/views/commissions/views/DetailsCommission.vue";

export default {
  components: {
    DetailsCommission,
  },
  async mounted() {
    await this.generateYears();
    await this.getReportCommission();
  },
  data() {
    return {
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      users: [],
      commissions: [],
      showCommission: false,
      commissionData: [],
      yearSelected: null,
      optionsYears: [],
      currentYear: parseInt(moment().format("YYYY"), 10),
      monthSelected: null,
      isBusy: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    columns() {
      const columns = ["user"];
      this.months.forEach((month) => {
        columns.push({
          key: month,
          label: month,
          class: "p-1",
          thClass: "bg-light-primary ",
        });
      });
      return columns;
    },
    validateUser() {
      return this.isCeo || this.isSupervisor ? null : this.currentUser.user_id;
    },
    moduleId() {
      return this.$route.matched[1].meta.module;
    },
  },
  methods: {
    getCommission(user, month) {
      const commission = this.commissions.find((item) => item.user === user);
      return commission ? commission[month] : 0;
    },
    calculateTotalMonth(month) {
      return this.commissions.reduce(
        (total, commission) => total + (commission[month] || 0),
        0
      );
    },
    async getReportCommission() {
      this.isBusy = true;
      const params = {
        p_year: this.yearSelected,
        p_user: this.validateUser,
        p_module: this.moduleId,
      };
      try {
        const { data } = await CommissionService.getReportCommission(params);
        let userSet = new Set();
        data.forEach((item) => {
          userSet.add(item.user);
        });
        this.users = Array.from(userSet);
        let resultadoAgrupado = {};

        data.forEach((item) => {
          if (!resultadoAgrupado[item.user]) {
            resultadoAgrupado[item.user] = { user: item.user };
          }
          resultadoAgrupado[item.user][item.month_name] = parseFloat(
            item.total
          );
          resultadoAgrupado[item.user]["user_id"] = item.user_id;
        });
        this.commissions = Object.values(resultadoAgrupado);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.isBusy = false;
      }
    },
    openModalDetails(data, month) {
      this.showCommission = true;
      this.commissionData = data;
      this.monthSelected = month;
    },
    generateYears() {
      this.optionsYears = [];
      for (let index = 2024; index <= this.currentYear; index++) {
        this.optionsYears.push({ id: index, value: index });
      }
      this.yearSelected = this.currentYear;
    },
    refreshTable() {
      this.getReportCommission();
    },
  },
};
</script>

<style scoped>
.custom-table >>> thead th {
  text-align: center !important; /* Center align the header */
}

.custom-table >>> tbody td,
.custom-table >>> tfoot th {
  text-align: right !important; /* Align body and footer cells to the right */
}

.custom-table >>> tfoot tr {
  position: sticky !important;
  bottom: 0 !important;
}
.custom-table >>> .bg-light-primary {
  background: rgba(0, 144, 231, 0.9) !important;
  color: #fff !important;
}
.custom-img {
  width: 200px;
  min-width: 100px;
  height: 200px;
  opacity: 0.1;
}

.custom-span {
  position: absolute;
  font-size: 15px;
  font-weight: bolder;
  text-align: center;
  letter-spacing: 1px;
  padding: 0px 20%;
  z-index: 2;
  color: #979595;
}
</style>

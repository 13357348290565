export default [
    {
        key: "client",
        label: "client",
        thClass: "text-left",
        tdClass: "text-left",
        visible: true,
    },
    {
        key: "account",
        label: "account",
        class: "text-center",
        visible: false,
    },
    {
        key: "description",
        label: "type",
        visible: true,
        class: "text-center",
    },
    {
        key: "commission_for",
        label: "commission for",
        visible: true,
        class: "text-center",
    },
    {
        key: "created_at",
        label: "created at",
        visible: true,
        class: "text-center",
    },
    {
        key: "type_commission",
        label: "type commission",
        visible: false,
        class: "text-center",
    },

    {
        key: "value_commission",
        label: "commission",
        visible: true,
        class: "text-center",
    },
    {
        key: "total_service",
        label: "total service",
        visible: true,
        class: "text-center",
    },
    {
        key: "amount",
        label: "commission generated",
        visible: true,
        class: "text-center",
    },
]
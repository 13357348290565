var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalName",attrs:{"modal-class":"modal-primary","title-class":"h3 text-white","title":"DETAIL OF COMMISSIONS","size":"xmd","hide-footer":"","centered":""},on:{"hidden":function($event){return _vm.closeModal()}},model:{value:(_vm.onControl),callback:function ($$v) {_vm.onControl=$$v},expression:"onControl"}},[_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('span',{staticClass:"text-white"},[_vm._v("USER")]),_c('div',{staticClass:"border border-primary rounded"},[_c('p',{staticClass:"text-primary d-flex align-items-center justify-content-center my-1s",staticStyle:{"gap":"5px"}},[_vm._v(" "+_vm._s(_vm.commissionData.user || "-")+" ")])])]),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('span',{staticClass:"text-white"},[_vm._v("YEAR")]),_c('div',{staticClass:"border border-primary rounded"},[_c('p',{staticClass:"text-primary d-flex align-items-center justify-content-center my-1s",staticStyle:{"gap":"5px"}},[_vm._v(" "+_vm._s(_vm.yearSelected)+" ")])])]),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('span',{staticClass:"text-white"},[_vm._v("MONTH")]),_c('div',{staticClass:"border border-primary rounded"},[_c('p',{staticClass:"text-primary d-flex align-items-center justify-content-center my-1s",staticStyle:{"gap":"5px"}},[_vm._v(" "+_vm._s(_vm.monthSelected)+" ")])])])],1)],1),_c('div',{staticClass:"table-responsive"},[_c('b-table',{ref:"trackingTable",staticClass:"blue-scrollbar mt-2",attrs:{"items":_vm.myProvider,"fields":_vm.visibleFields,"primary-key":"id","responsive":"sm","show-empty":"","sticky-header":"50vh","busy":_vm.isBusy,"no-provider-filtering":"","foot-clone":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(created_at)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(value))+" ")]}},{key:"cell(client)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(item.client))]),_c('br'),_c('span',{attrs:{"variant":"light-primary"}},[_vm._v(_vm._s(item.account))])]}},{key:"cell(account)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(_vm._s(value))])]}},{key:"cell(commission_for)",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"text-info"},[_vm._v(_vm._s(value))])]}},{key:"cell(value_commission)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.type_commission == "AMOUNT" ? "$" : "")+" "+_vm._s(_vm._f("currencyTwo")(item.value_commission))+" "+_vm._s(item.type_commission == "PERCENTAGE" ? "%" : ""))])]}},{key:"cell(amount)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("myMoney")(_vm._f("currencyTwo")(value)))+" ")]}},{key:"cell(total_service)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("myMoney")(_vm._f("currencyTwo")(value)))+" ")]}},{key:"foot(value_commission)",fn:function(){return [_c('div',{staticClass:"text-center text-primary d-flex align-items-center justify-content-center"},[_c('amg-icon',{staticClass:"mr-1",attrs:{"icon":"MoneyBagIcon","size":"20"}}),_c('span',{staticClass:"h5 text-primary m-0",staticStyle:{"font-weight":"bold"}},[_vm._v("TOTAL")])],1)]},proxy:true},{key:"foot(total_service)",fn:function(){return [_c('b-badge',{staticStyle:{"font-size":"13px"},attrs:{"variant":"light-success"}},[_vm._v(_vm._s(_vm._f("myMoney")(_vm._f("currencyTwo")(_vm.sumTotalService()))))])]},proxy:true},{key:"foot(amount)",fn:function(){return [_c('b-badge',{staticStyle:{"font-size":"13px"},attrs:{"variant":"light-success"}},[_vm._v(_vm._s(_vm._f("myMoney")(_vm._f("currencyTwo")(_vm.sumAmount()))))])]},proxy:true},{key:"foot()",fn:function(data){return [_c('span')]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-modal
    v-model="onControl"
    ref="modalName"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="DETAIL OF COMMISSIONS"
    size="xmd"
    @hidden="closeModal()"
    hide-footer
    centered
  >
    <div>
      <b-row>
        <b-col cols="12" md="4">
          <span class="text-white">USER</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ commissionData.user || "-" }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="4">
          <span class="text-white">YEAR</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ yearSelected }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="4">
          <span class="text-white">MONTH</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ monthSelected }}
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="table-responsive">
      <b-table
        ref="trackingTable"
        :items="myProvider"
        :fields="visibleFields"
        primary-key="id"
        responsive="sm"
        show-empty
        sticky-header="50vh"
        class="blue-scrollbar mt-2"
        :busy="isBusy"
        no-provider-filtering
        foot-clone
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(created_at)="{ value }">
          {{ value | myGlobalDay }}
        </template>
        <template #cell(client)="{ item }">
          <span class="text-primary">{{ item.client }}</span
          ><br />
          <span variant="light-primary">{{ item.account }}</span>
        </template>
        <template #cell(account)="{ value }">
          <b-badge variant="light-primary">{{ value }}</b-badge>
        </template>
        <template #cell(commission_for)="{ value }">
          <span class="text-info">{{ value }}</span>
        </template>
        <template #cell(value_commission)="{ item }">
          <span
            >{{ item.type_commission == "AMOUNT" ? "$" : "" }}
            {{ item.value_commission | currencyTwo }}
            {{ item.type_commission == "PERCENTAGE" ? "%" : "" }}</span
          >
        </template>
        <template #cell(amount)="{ value }">
          {{ value | currencyTwo | myMoney }}
        </template>
        <template #cell(total_service)="{ value }">
          {{ value | currencyTwo | myMoney }}
        </template>
        <template #foot(value_commission)
          ><div
            class="text-center text-primary d-flex align-items-center justify-content-center"
          >
            <amg-icon icon="MoneyBagIcon" class="mr-1" size="20" />
            <span class="h5 text-primary m-0" style="font-weight: bold"
              >TOTAL</span
            >
          </div>
        </template>
        <template #foot(total_service)>
          <b-badge variant="light-success" style="font-size: 13px">{{
            sumTotalService() | currencyTwo | myMoney
          }}</b-badge>
        </template>
        <template #foot(amount)>
          <b-badge variant="light-success" style="font-size: 13px">{{
            sumAmount() | currencyTwo | myMoney
          }}</b-badge>
        </template>
        <template #foot()="data">
          <span></span>
        </template>
      </b-table>
    </div>
  </b-modal>
</template>

<script>
import CommissionService from "@/views/specialists/sub-modules/financial-approval/views/commissions/services/financial-commissions.service";
import fields from "@/views/specialists/sub-modules/financial-approval/views/commissions/data/fieldsDetails";
export default {
  props: {
    commissionData: {
      required: true,
    },
    yearSelected: {
      required: true,
    },
    monthSelected: {
      required: true,
    },
  },
  data() {
    return {
      onControl: false,
      isBusy: false,
      fields: fields,
      items: [],
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  created() {
    this.onControl = true;
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    async myProvider() {
      try {
        const params = {
          p_user: this.commissionData.user_id,
          p_year: this.yearSelected,
          p_month: this.monthSelected,
        };
        const { data } = await CommissionService.getDetailCommission(params);
        this.items = data;
        return data;
      } catch (error) {
        console.log(error.response.data)
        if(error.response.status == 422){
          var errors = error.response.data.errors
          var errorMessage = ""
          for(var key in errors){
            errorMessage += errors[key][0] + '\n'
          }
          this.showWarningSwal(errorMessage)
        }else{
          this.showErrorSwal(error.response.data.message)
        };
      }
    },
    sumTotalService() {
      let totalAmount = 0;
      this.items.forEach((item) => {
        totalAmount += parseFloat(item.total_service);
      });
      return totalAmount;
    },
    sumAmount() {
      let totalAmount = 0;
      this.items.forEach((item) => {
        totalAmount += parseFloat(item.amount);
      });
      return totalAmount;
    },
  },
};
</script>